.pth-circle {
    .skill {
        width: 220px;
        height: 220px;
        // background: cornflowerblue;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .outer {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        padding: 20px;
        box-shadow: 6px 6px 10px 5px rgba(0, 0, 0, 0.35),
                    -6px -6px 10px 2px rgba(255,255,255,2)
    }
    .inner {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        // border: 1px solid red;
        color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 62px;
        font-weight: 500;
    }
    .circle {
        fill: none;
        stroke: url(#GradientColor);
        stroke-width: 4px;
        stroke-dasharray: 472;
        stroke-dashoffset: 100;
    }
    .circle1 {
        fill: none;
        stroke: url(#GradientColor1);
        stroke-width: 4px;
        stroke-dasharray: 472;
        stroke-dashoffset: 100;
    }
    .circle2 {
        fill: none;
        stroke: url(#GradientColor2);
        stroke-width: 4px;
        stroke-dasharray: 472;
        stroke-dashoffset: 100;
    }
    svg {
        position: absolute;
        top: 0;
        left: 0;
    }
    .dot {
        position: absolute;
        // background: #f00;
        inset: 5px;
        z-index: 10;
    }
    .dot::before{
        content: '';
        position: absolute;
        top: 25px;
        left: 50px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: var(--s);
        box-shadow: 0 0 10px var(--s),
        0 0 10px var(--s)
    }
    .dot::after{
        content: '';
        position: absolute;
        bottom: 100px;
        right: 10px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: var(--e);
        box-shadow: 0 0 10px var(--e),
        0 0 10px var(--e)
    }
    .pth-text-desc {
        color: var(--s);
        font-weight: 600;
    }
}
.pth-pyramid {
    position: relative;
    width: calc(440px * var(--percent-pyramid)); 
    height: calc(500px * var(--percent-pyramid));
    .pth-pyramid-description () {
        color: red;
        position: absolute;
        width: 100px;
        height: 100px;
        font-weight: 600;
        left: 0px;
    }
    .pth-pyramid-pinnacle {
        .pth-pyramid-description;
        top: calc(100px * var(--percent-pyramid));
    }
    .pth-pyramid-challenge {
        .pth-pyramid-description;
        top: calc(400px * var(--percent-pyramid));
    }
    .pth-tower-top () {
        flex-direction: column;
        position: absolute;
        width: calc(30px * var(--percent-pyramid));
        height: calc(30px * var(--percent-pyramid));
        background: #F6E0FF;
        border: 1px solid #9C27B0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        font-weight: 600;
    }
    .pth-tower-number{
        position: absolute;
    }
    .pth-tower-description{
        position: absolute;
        top: calc(25px * var(--percent-pyramid));
        width: calc(100px * var(--percent-pyramid));
        text-align: center;
    }
    .pth-tower-month {
        .pth-tower-top;
        width: 25px;
        height: 25px;
        background: #80D2FF;
        border: 1px solid #16B4D8;
        top: calc(240px * var(--percent-pyramid));
        left: calc(13px * var(--percent-pyramid));
    }
    .pth-tower-day {
        .pth-tower-top;
        width: 25px;
        height: 25px;
        background: #80D2FF;
        border: 1px solid #16B4D8;
        top: calc(240px * var(--percent-pyramid));
        left: calc(207px * var(--percent-pyramid));
    }
    .pth-tower-year {
        .pth-tower-top;
        width: 25px;
        height: 25px;
        background: #80D2FF;
        border: 1px solid #16B4D8;
        top: calc(240px * var(--percent-pyramid));
        left: calc(405px * var(--percent-pyramid));
    }
    .pth-tower-top-1 {
        .pth-tower-top;
        top: calc(140px * var(--percent-pyramid));;
        left: calc(157px * var(--percent-pyramid));
    }
    .pth-tower-top-3 {
        .pth-tower-top;
        top: calc(70px * var(--percent-pyramid));
        left: calc(207px * var(--percent-pyramid));
    }
    .pth-tower-top-2 {
        .pth-tower-top;
        top: calc(140px * var(--percent-pyramid));
        left: calc(257px * var(--percent-pyramid));
    }
    .pth-tower-top-4 {
        .pth-tower-top;
        top: calc(-7px * var(--percent-pyramid));
        left: calc(207px * var(--percent-pyramid));
    }
    .pth-tower-try-1 {
        .pth-tower-top;
        top: calc(337px * var(--percent-pyramid));
        left: calc(157px * var(--percent-pyramid));
    }
    .pth-tower-try-3 {
        .pth-tower-top;
        top: calc(397px * var(--percent-pyramid));
        left: calc(207px * var(--percent-pyramid));
    }
    .pth-tower-try-2 {
        .pth-tower-top;
        top: calc(337px * var(--percent-pyramid));
        left: calc(257px * var(--percent-pyramid));
    }
    .pth-tower-try-4 {
        .pth-tower-top;
        top: calc(487px * var(--percent-pyramid));
        left: calc(207px * var(--percent-pyramid));
    }
}
.pth-table-history {
    border-radius: 5px;
    border-top-left-radius: 0;
    .ant-table-thead {
        background: #80D2FF !important;
        .ant-table-cell {
        color: #000;
        }
    }
    .ant-table.ant-table-bordered {
        .ant-table-container {
            border-left: 1px solid #BFE8FF;
            .ant-table-content {
                th {
                    border-right: 1px solid #BFE8FF;
                }
                td {
                    border-right: 1px solid #BFE8FF;
                    border-bottom: 1px solid #BFE8FF;
                    border-top: 1px solid #BFE8FF;
                }
            }
        }
    }
}

.pth-table-index {
    border-radius: 5px;
    border-top-left-radius: 0;
    .ant-table-thead {
        background: #80D2FF !important;
        .ant-table-cell {
        color: #000;
        }
    }
    .ant-table.ant-table-bordered {
        .ant-table-container {
            border-left: 1px solid blue;
            .ant-table-content {
                th {
                    border-right: 1px solid blue;
                }
                tr:first-child{
                    border-top: 1px solid blue;
                }
                td {
                    border-right: 1px solid blue;
                    border-bottom: 1px solid blue;
                    border-top: none;
                }
            }
        }
    }
}
.pth-gift::before{
    content: "\f06b";
}
.pth-animation-gift {
    animation-name: gift;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
@keyframes gift {
    0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
}
.input-pass .ant-input{
  height: 30px;
}
.pth-chart-bar {
    background: var(--c);
    height: 20px;
    width: var(--w);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}
.pth-cell-h45 {
    tbody tr td {
        height: 45px;
        width: 45px;
    }
}

@media all and (max-width: 480px) {
    .pth-content-2-3 {
        width: 100%;
        padding: 0px 15px;
    }
    .pth-content-chart {
        width: 100% !important;
        padding: 0px !important;
    }
    .pth-content-1-3 {
        width: 100% !important;
        padding: 0px 15px !important;
    }
    
    .pth-table-reponse {
        .ant-table-thead tr {
            th{
                white-space:unset!important;
            }
        }
        .ant-table-tbody {
            tr {
                td{
                white-space:unset!important;
                }
            }
        }
    }
    .pth-modal-reponse {
        width: 80%
    }
    :root {
        font-size: 12px;
        --percent-pyramid: 0.7;
    }
    #chart {
        padding: 0px;
    }
}
@media all and (min-width: 480px) and (max-width: 800px) {
    .pth-content-2-3 {
        width: 90% !important;
        padding: 0px !important;
    }
    .pth-content-chart {
        width: 100% !important;
        padding: 0px !important;
    }
    .pth-content-1-3 {
        width: 50% !important;
        padding: 0px !important;
    }
    .pth-modal-reponse {
        width: 50%
    }
    :root {
        font-size: 12px;
        --percent-pyramid: 0.7;
    }
    #chart {
        padding: 0px;
    }
}
@media all and (min-width: 1600px) {
    /* For desktop: */
    
    .pth-screen {
        height: auto;
    }
    .pth-screen-bg {
        background-image: url("../../../assets/images/bg.jpg") !important;
    }
}
@media all and (max-width: 1600px) {
    /* For mobile: */
    
    .pth-screen {
        height: auto;
    }
}
@media all and (min-width: 1280px){
    .pth-content-2-3 {
        width: 60% !important;
        padding: 0px !important;
    }
    .pth-content-chart {
        width: 60% !important;
        padding: 0px !important;
    }
    .pth-content-1-3 {
        width: 33% !important;
        padding: 0px !important;
    }
    .pth-modal-reponse {
        width: 50%
    }
    
    :root {
        font-size: 14px;
        --percent-pyramid: 1;
    }
    #chart {
        padding: 0px 20%;
    }
}
@media all and (min-width: 920px) and (max-width: 1280px) {
    .pth-content-chart {
        width: 60% !important;
        padding: 0px !important;
    }
    .pth-content-1-3 {
        width: 33% !important;
        padding: 0px !important;
    }
    .pth-modal-reponse {
        width: 50%
    }
    
    :root {
        font-size: 14px;
        --percent-pyramid: 1;
    }
    #chart {
        padding: 0px 20%;
    }
}
@media all and (min-width: 800px) and (max-width: 920px) {
    .pth-content-2-3 {
        width: 80% !important;
        padding: 0px !important;
    }
    #chart {
        padding: 0px 15%;
    }
}
@media all and (max-width: 992px) {
    .ant-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
@media all and (min-width: 600px) and (max-width: 800px) {
    #chart {
        padding: 0px 15%;
    }
}
.ant-modal-mask {
    background-image: url("../../../assets/images/bg.jpg")
}

.pth-text-24 {
    font-size: 1.5rem
}
.pth-tower-description, .pth-tower-description p{
    font-size: 1rem
}