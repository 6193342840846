.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  color: #000000;
}
.ant-input-affix-wrapper > input.ant-input {
  color: #000000;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  color: #000000;
}
.ant-form-item-control-input {
  min-height: auto;
}
.ant-input-number {
  border: 0px;
  background-color: #FFFFFF;
  border: 1px solid #d9d9d9;
  padding-top: 4px;
}
.t-a-end {
  text-align: end !important;
}
.t-a-end input {
  text-align: end;
}
textarea.ant-input {
  color: black;
}
html,
body,
#root {
  height: 100%;
}
.colorWeak {
  filter: invert(80%);
}
.ant-layout {
  min-height: 100vh;
}
.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  left: unset;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.divSpanHover:hover {
  color: #E46463;
}
.ant-notification {
  z-index: 99999;
}
.contentBody {
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  background-color: #FFFFFF;
}
.pth-form-item-label label {
  color: #FFFFFF;
}
.a-login {
  color: #BFC4C9 !important;
}
.pth-animation-type-1 .pth-animation-content {
  position: relative;
}
.pth-animation-type-1 .pth-animation-content::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  background-color: #161614;
  animation: typing 3.5s steps(20) forwards;
}
.pth-animation-type-1 .pth-animation-delay::after {
  animation-delay: 3.7s;
}
@keyframes typing {
  100% {
    left: 100%;
  }
}
.pth-animation-type-2 .pth-animation-content {
  position: relative;
  animation: moving 2.5s forwards;
}
.pth-animation-type-2 .pth-animation-delay-1 {
  animation-delay: 2.5s;
}
.pth-animation-type-2 .pth-animation-delay-2 {
  animation-delay: 5s;
}
@keyframes moving {
  from {
    left: -150px;
  }
  to {
    left: 0px;
  }
}
.pth-modal-transparent .ant-modal-content {
  background-color: transparent;
}
.pth-modal-transparent .ant-modal-content p,
.pth-modal-transparent .ant-modal-content span {
  font-size: 18px;
}
.pth-modal-transparent .ant-modal-content p {
  color: #FFFFFF;
}
