@tailwind base;
@tailwind components;
@tailwind utilities;

/* .ant-menu-submenu-arrow{
    left: 10px !important;
    width: 15px;
    
} */

/* Neutral Color 1.1 */
.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow {
  color: #414141 !important;
}

.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow:hover {
  color: #414141 !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title:active {
  color: #414141 !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  color: #414141 !important;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  color: #414141 !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #be1e2d !important;
  color: #be1e2d;
  background-color: red !important;
}

.layout-dashboard .ant-layout-sider.sider-primary {
  margin: 0px;
}

.ant-layout {
  background: #f0f2f5;
}
.layout-dashboard .ant-layout-sider.sider-primary {
  top: 48px;
}


@media (max-width: 992px) {
  .ant-row {
    margin-left: 0;
    margin-right: 0;
  }
}

/* .ant-select-tree-list-holder{
      max-height: 500px !important;
  } */

.mainPages {
  width: 100%;
  height: 100%;
  padding: 0 16px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #0F5C97;
}
.width0 {
  height: 0 !important;
  max-height: 0 !important;
  transition: all 0s;
}
