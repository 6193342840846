
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    color: @neutral_color_1_2;
    // font-size: 14px !important;
    // font-weight: '400'!important;
}

.ant-input-affix-wrapper > input.ant-input {
      color: @neutral_color_1_2;
    // font-size: 14px !important;
    // font-weight: '400'!important;
}

a, area, button, [role='button'], input:not([type='range']), label, select, summary, textarea{
      color: @neutral_color_1_2;
    // font-size: 14px !important;
    // font-weight: '400'!important;
} 

.ant-form-item-control-input {
    min-height:auto;
}


.ant-input-number {
    border:0px;
    background-color: @neutral_color_1_8;
    border: 1px solid #d9d9d9;
    padding-top:4px;
    // display: flex;
    // justify-content: center;
}

.t-a-end {
    text-align: end !important;
    input {
      text-align: end;
    }
  }

textarea.ant-input {
    color:black;
}