@import '../../styles/variable.less';

.title {
  color:'red';
  font-weight: 600;
  margin-top: 160px;
}
// .ant-input-affix-wrapper > input.ant-input {
//   background-color: white !important;
// }

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button{
    background-color: #BE1E2D;
    border:0px;
} 

.input_signIn{
  // background-color: red !important
  border:1px solid @neutral_color_1_6;
  border-radius: 6px !important;
}