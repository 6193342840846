// @import '~antd/es/style/themes/default.less';
@import './formStyles.less';
@import './variable.less';

html,
body,
#root {
  height: 100%;
}

.colorWeak {
  filter: invert(80%);
}

.ant-layout {
  min-height: 100vh;
}
.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  left: unset;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.divSpanHover:hover{
  // background-color: red;
  color: @accent_color_4_3 ;
}

.ant-notification {
  z-index: 99999;
}

.contentBody{
  // margin-top: 15px;
  border-radius: 5px;
  padding:16px;
  // height: 100%;
  width: 100%;
  background-color: @neutral_color_1_8;
}

.pth-form-item-label {
  label {
    color: @neutral_color_1_8;
  }
}
.a-login {
  color: @neutral_color_1_5 !important;
}
.pth-animation-type-1 .pth-animation-content{
  position: relative;
}
.pth-animation-type-1 .pth-animation-content::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  // background-color: #0D0A1D;
  background-color: #161614;
  animation: typing 3.5s steps(20) forwards;
}
.pth-animation-type-1 .pth-animation-content-first::after{
  // border-left: 2px solid @accent_color_4_5;
}
.pth-animation-type-1 .pth-animation-delay::after{
  animation-delay: 3.7s;
  // border-left: 2px solid @accent_color_4_5;
}
@keyframes typing {
  100% {
    left: 100%;
  }
}

.pth-animation-type-2 .pth-animation-content{
  position: relative;
  animation: moving 2.5s forwards;
}
.pth-animation-type-2 .pth-animation-delay-1{
  animation-delay: 2.5s;
}
.pth-animation-type-2 .pth-animation-delay-2{
  animation-delay: 5.0s;
}
@keyframes moving {
  from {left: -150px;}
  to {left: 0px;}
}
.pth-modal-transparent .ant-modal-content{
  background-color: transparent;
  p, span {
    font-size: 18px;
  }
  p {
    color: @neutral_color_1_8;
  }
  
}