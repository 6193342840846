.overlaySpinner12 {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99;
  
  .waveLoading12 {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    div {
      width: 33%;
      position: relative;
      display: inline-block;
      color: #fff;
      font-size: 20px;
      margin: 2px;
      opacity: 0;
      animation: animateTest 0.5s ease-in-out calc(1s * var(--c)) 1,
        animateTestParent 1s ease-in-out calc(1s * var(--e)) 1;
      animation-fill-mode: forwards;
    }
    @keyframes animateTest {
      0% {
        left: -200px;
        opacity: 0;
      }
      100% {
        left: 0;
        opacity: 1;
      }
    }
  }
  @keyframes animateTestParent {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.finishAnimation {
  border-radius: 50%;
  border: 2px solid #fff;
  animation: animateTestParent2 1s ease-in-out calc(1s * var(--s)) 1;
  animation-fill-mode: forwards;
  width: 200px;
  height: 200px;
  position: relative;
  transform: translateY(-160px);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  font-size: 62px;
  font-weight: 500;
  color: #fff;
  @keyframes animateTestParent2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.finishAnimationChart {
  animation: animateTestParent3 1s ease-in-out calc(1s * var(--s)) 1;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateY(-180px);
  @keyframes animateTestParent3 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.pth-sign-research {
  display: none !important;
}
.pth-bg-result-research {
  background-color: #F4CA64;
}

@media all and (min-width: 480px) {
  .overlaySpinner12 {
    flex-direction: row;
    .waveLoading12 {
      margin-bottom: 0px;
      flex-direction: column;
      transform: translateX(80px);
      width: initial;
      div {
        width: initial;

      }
    }
  }
  .finishAnimation {
    transform: translateX(-130px);
  }
  .finishAnimationChart {
    transform: translateY(0px);
  }
  .pth-sign-research {
    display: inherit !important;
  }
  .pth-bg-result-research {
    background-color: inherit;
  }
}