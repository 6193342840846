@import '../../styles/variable.less';

// .btn__light, .buttonLight:hover, .buttonLight:active, .buttonLight:focus{
//     border: 1px solid @neutral_color_1_5;
//     color:@neutral_color_1_3;
//     font-weight: 400;
//     font-size: 14px;
// }

// .buttonDanger, .buttonDanger:hover, .buttonDanger:active, .buttonDanger:focus{
//     border: 1px solid @primary_color_1_1;
//     background-color: @primary_color_1_1;
//     color:@neutral_color_1_8;
//     font-weight: 400;
//     font-size: 14px;
// }

// .btn__ddv, .btn__ddv:hover, .btn__ddv:active, .btn__ddv:focus{
//   background-color: @primary_color_1_1 !important;
//   color:@neutral_color_1_8 !important;
//   border:1px solid @primary_color_1_1!important;
//   font-weight: 400 !important;
//   font-size: 14px !important;
//   height: 42px !important;
// }

.btn__default, .btn__default:hover, .btn__default:active, .btn__default:focus{
  background-color: @accent_color_3_5 !important;
  color:@neutral_color_1_8 !important;
  border:1px solid @accent_color_3_5!important;
  font-weight: 400 !important;
  font-size: 14px !important;
  height: 42px !important;
}

.btn__pth, .btn__pth:hover, .btn__pth:active, .btn__pth:focus{
  background-color: @accent_color_5_5 !important;
  color:@neutral_color_1_8 !important;
  border:1px solid @accent_color_5_5!important;
  font-weight: 400 !important;
  font-size: 14px !important;
  height: 42px !important;
}

.btn__primary, .btn__primary:hover, .btn__primary:active, .btn__primary:focus{
  background-color: @accent_color_1_2 !important;
  color:@neutral_color_1_8 !important;
  border:1px solid @accent_color_1_2!important;
  font-weight: 400  !important;
  font-size: 14px  !important;
  height: 42px !important;
}
.btn__transparent, .btn__transparent:hover, .btn__transparent:active, .btn__transparent:focus{
  background-color: transparent !important;
  color:@neutral_color_1_8 !important;
  border:1px solid @neutral_color_1_8!important;
  font-weight: 400  !important;
  font-size: 14px  !important;
  height: 42px !important;
}

.btn__light, .btn__light:hover, .btn__light:active, .btn__light:focus{
  border: 1px solid @accent_color_1_3 !important;
  color:@neutral_color_1_2 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  height: 42px !important;
}

.btn__green, .btn__green:hover, .btn__green:active, .btn__green:focus{
  background-color: @neutral_color_1_4 !important;
  border: 1px solid @neutral_color_1_4 !important;
  color:@neutral_color_1_8 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  height: 42px !important;
}

.btn__second_green, .btn__second_green:hover, .btn__second_green:active, .btn__second_green:focus{
  background-color: @accent_color_1_2 !important;
  border: 1px solid @accent_color_1_2 !important;
  color:@neutral_color_1_8 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  height: 42px !important;
}

.btn__danger, .btn__danger:hover, .btn__danger:active, .btn__danger:focus{
  background-color: @primary_color_1_4 !important;
  border: 1px solid @primary_color_1_4 !important;
  color:@primary_color_1_1 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  height: 42px !important;
}

.none_border{
  border:0px !important;
}